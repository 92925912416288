import './App.css';
import React, { useEffect, useContext,useState } from 'react';

import { BrowserRouter as Router, Route, Routes, Link, useNavigate  } from 'react-router-dom';
import {Context1} from './Context1';
import ParagraphContent from './ParagraphContent.json';
import SendAudio from './WhisperApp';
import {BlogHeader, BlogPost} from './Blog';


import { marked } from 'marked';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css'; // You can choose other styles


marked.setOptions({
  highlight: function (code, language) {
    console.log("ok")
    console.log("Language detected: ", language); // Check if this is being called
    const validLanguage = hljs.getLanguage(language) ? language : 'plaintext';
    return hljs.highlight(validLanguage, code).value;
  },
  langPrefix: 'hljs language-' // This is important for the correct CSS class
});



function App() {

    // const widthh = window.innerWidth * 0.3; // 30% of screen width
    // return (<div className="App">   <LandingPage/>    </div>  );
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/technology/*" element={<TechnologyPage />} />
                    <Route path="/about" element={<ABOUTPage />} />
                    {/* Add more routes as needed add _redirects file to make this works*/}
                </Routes>
            </div>
        </Router>
        // <div>
        //     <SendAudio/>
        // </div>
    );
}

export default App;





export function LandingPage() {
    const handleClickCHATBOT = () => {window.open('https://dev.entrosystem.com', '_blank');};
    return (
        <div>
            <div className='Page1' style={{backgroundImage: 'url(images/BannerPage1.jpg)'}} >
                < MainPageTemplate />
                <div className="center-container2"><div className='TryChat' onClick={handleClickCHATBOT} style={{cursor: 'pointer'}} >TRY CHAT WITH OUR AI AGENT</div></div>
                <div className="center-container3"><div className='EntrosytemProvide1' >Entrosystem provide state of the art AI Agent as database retrieval agent</div></div>
                <div className="center-container4"><div className='EntrosytemProvide2' >data analyzer and custom chatbot </div></div>
            </div>

            <div className='FullScreenPage'>
                <div className='HalfScreen' style={{backgroundImage: 'url(images/g.jpg)'}} >< SendAudio /></div>
                <div className='HalfScreen2' style={{backgroundColor: 'rgb(0, 0, 0)'}} >
                    <p className='AppletTitle'>{ParagraphContent[0].title[2]}</p>
                    <p className='AppletParagraph'>{ParagraphContent[1].content[2]}</p>
                </div>
            </div>
            <div className='FullScreenPage'>
                <div className='HalfScreen' style={{backgroundColor: 'rgb(0, 0, 0)'}} >
                <p className='AppletTitle'>{ParagraphContent[0].title[3]}</p>
                <p className='AppletParagraph'>{ParagraphContent[1].content[3]}</p></div>
                <div className='HalfScreen' style={{backgroundImage: 'url(images/f.jpg)'}} ></div>
            </div>
            <div className='FullScreenPage'>
                <div className='HalfScreen' style={{backgroundImage: 'url(images/e.jpg)'}} ></div>
                <div className='HalfScreen' style={{backgroundColor: 'rgb(0, 0, 0)'}} >
                <p className='AppletTitle'>{ParagraphContent[0].title[1]}</p>
                <p className='AppletParagraph'>{ParagraphContent[1].content[1]}</p></div>
            </div>
            <div className='FullScreenPage'>
                <div className='HalfScreen' style={{backgroundColor: 'rgb(0, 0, 0)'}} >
                <p className='AppletTitle'>{ParagraphContent[0].title[0]}</p>
                <p className='AppletParagraph'>{ParagraphContent[1].content[0]}</p></div>
                <div className='HalfScreen' style={{backgroundImage: 'url(images/d.jpg)'}} ></div>
            </div>
            <div className='FullScreenPage'>
                <div className='HalfScreen' style={{backgroundImage: 'url(images/a.jpg)'}} ></div>
                <div className='HalfScreen' style={{backgroundColor: 'rgb(0, 0, 0)'}} >
                <p className='AppletTitle'>{ParagraphContent[0].title[4]}</p>
                <p className='AppletParagraph'>{ParagraphContent[1].content[4]}</p></div>
            </div>
            <div className='FullScreenPage'>
                <div className='HalfScreen' style={{backgroundColor: 'rgb(0, 0, 0)'}} ></div>
                <div className='HalfScreen' style={{backgroundImage: 'url(images/b.jpg)'}} ></div>
            </div>
            <div className='Page2' style={{backgroundImage: 'url(images/BannerPage1.jpg)'}} ></div>

        </div>
    );
}





export function TechnologyPage() {
    // const navigate = useNavigate();
        return (
            <div>
                <Routes>
                    <Route path="/" element={
                        <div>
                            {/* <div style={{height: 'calc(var(--dynamic-height)*0.12)', backgroundImage: 'url(images/BannerPage1.jpg)'}} ><MainPageTemplate/></div> */}
                            <div style={{height: 'calc(var(--dynamic-height)*0.08)', backgroundColor: 'rgb(25, 25, 25)'}} ><MainPageTemplate/></div>
                            <div className='FullScreenPage' style={{height: 'calc(var(--dynamic-height)*0.46)'}}>
                                <div className='QuarterScreen' style={{backgroundImage: 'url(/post/media/AttentionMatrix.jpg)', }} >
                                    <Link style={{textDecoration:'none'}} to="transformer"><BlogHeader postPath="/post/Transformer.md"  /></Link></div>
                                <div className='QuarterScreen' style={{backgroundImage: 'url(/post/media/MoE_Dalle.webp)'}} >
                                    <Link style={{textDecoration:'none'}} to="moe"><BlogHeader postPath="/post/MoE.md"  /></Link></div>
                                <div className='QuarterScreen' style={{backgroundImage: 'url(/post/media/GANMnist.jpg)'}} >
                                    <Link style={{textDecoration:'none'}} to="gan"><BlogHeader postPath="/post/GAN.md"  /></Link></div>
                                <div className='QuarterScreen' style={{backgroundImage: 'url(/post/media/LinearActuator.jpg)'}} >
                                    <Link style={{textDecoration:'none'}} to="humanoid"><BlogHeader postPath="/post/Humanoid.md"  /></Link></div>
                            </div>
                            <div className='FullScreenPage' style={{height: 'calc(var(--dynamic-height)*0.46)'}}>
                                <div className='QuarterScreen' style={{backgroundImage: 'url(/post/media/DQN.jpg)', }} >
                                    <Link style={{textDecoration:'none'}} to="dqn"><BlogHeader postPath="/post/DQN.md"  /></Link></div>
                                <div className='QuarterScreen' style={{backgroundColor:'green'}} >
                                    <Link style={{textDecoration:'none'}} to="image-diffusion"><BlogHeader postPath="/post/ImageDiffusion.md"  /></Link></div>
                                <div className='QuarterScreen' style={{backgroundColor:'blue'}} >
                                    <Link style={{textDecoration:'none'}} to="speech-synthesis"><BlogHeader postPath="/post/SpeechSynthesis.md"  /></Link></div>
                                <div className='QuarterScreen' style={{backgroundColor:'red'}} >
                                    <Link style={{textDecoration:'none'}} to="cnn"><BlogHeader postPath="/post/CNN.md"  /></Link></div>
                            </div>
                        </div>
                    } />

                    <Route path="/transformer" element={<div>
                            <div style={{height: 'calc(var(--dynamic-height)*0.12)', backgroundColor: 'rgb(25, 25, 25)'}} ><MainPageTemplate/></div>
                            <BlogPost postPath="/post/Transformer.md" />
                        </div>} />
                    <Route path="/moe" element={<div>
                            <div style={{height: 'calc(var(--dynamic-height)*0.12)', backgroundColor: 'rgb(25, 25, 25)'}} ><MainPageTemplate/></div>
                            <BlogPost postPath="/post/MoE.md" />
                        </div>} />
                    <Route path="/gan" element={<div>
                            <div style={{height: 'calc(var(--dynamic-height)*0.12)', backgroundColor: 'rgb(25, 25, 25)'}} ><MainPageTemplate/></div>
                            <BlogPost postPath="/post/GAN.md" />
                        </div>} />
                    <Route path="/humanoid" element={<div>
                            <div style={{height: 'calc(var(--dynamic-height)*0.12)', backgroundColor: 'rgb(25, 25, 25)'}} ><MainPageTemplate/></div>
                            <BlogPost postPath="/post/Humanoid.md" />
                        </div>} />
                    <Route path="/dqn" element={<div>
                            <div style={{height: 'calc(var(--dynamic-height)*0.12)', backgroundColor: 'rgb(25, 25, 25)'}} ><MainPageTemplate/></div>
                            <BlogPost postPath="/post/DQN.md" />
                        </div>} />
                    <Route path="/image-diffusion" element={<div>
                            <div style={{height: 'calc(var(--dynamic-height)*0.12)', backgroundColor: 'rgb(25, 25, 25)'}} ><MainPageTemplate/></div>
                            <BlogPost postPath="/post/ImageDiffusion.md" />
                        </div>} />
                    <Route path="/speech-synthesis" element={<div>
                            <div style={{height: 'calc(var(--dynamic-height)*0.12)', backgroundColor: 'rgb(25, 25, 25)'}} ><MainPageTemplate/></div>
                            <BlogPost postPath="/post/SpeechSynthesis.md" />
                        </div>} />
                    <Route path="/cnn" element={<div>
                            <div style={{height: 'calc(var(--dynamic-height)*0.12)', backgroundColor: 'rgb(25, 25, 25)'}} ><MainPageTemplate/></div>
                            <BlogPost postPath="/post/CNN.md" />
                        </div>} />
                </Routes>
            </div>
        )
    }

export function ABOUTPage() {
    return (
        <div>
            <div style={{height: 'calc(var(--dynamic-height)*0.12)', backgroundColor: 'rgb(0, 0, 0)'}} > <MainPageTemplate/> </div>
            <div className='FullScreenPage' style={{height: 'calc(var(--dynamic-height)*0.88)'}}>
                <div className='HalfScreen' style={{height: 'calc(var(--dynamic-height)*0.88)', backgroundPosition: 'center', backgroundImage: 'url(images/g.jpg)'}} ></div>
                <div className='HalfScreen' style={{height: 'calc(var(--dynamic-height)*0.88)', backgroundColor: 'rgb(0, 0, 0)'}} >
                    <div style={{ textAlign: 'left', width: '80%', textAlign: 'left', left: '10%', top: '8%', position: 'relative', color: 'rgb(255, 255, 255)', fontSize: '18px'}}>
                    <p>Transform your business with our advanced conversational AI platform—offering state-of-the-art voice recognition, lifelike voice synthesis, and highly accurate semantic search capabilities for a seamless user experience.</p>
                    <p>email : ai@entrosystem.com</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

// export function VectorEmbedding () {
//   return (
//     <div>
//       <div className='Page1'>
//         <MainPageTemplate/>
//       </div>
//       <div className='Page2' style={{backgroundColor: 'rgb(50, 50, 50)'}}></div>
//       <div className='Page3' style={{backgroundColor: 'rgb(150, 50, 50)'}}></div>
//     </div>
//   )
// }

// export function LargeLanguageModel() {
//   return (
//     <div>
//       <div className='Page1'>
//         <MainPageTemplate/>
//       </div>
//       <div className='Page2' style={{backgroundColor: 'rgb(50, 50, 50)'}}></div>
//       <div className='Page3' style={{backgroundColor: 'rgb(150, 50, 50)'}}></div>
//     </div>
//   )
// }

// export function SpeechTranscription() {
//   return (
//     <div>
//       <div className='Page1'>
//         <MainPageTemplate/>
//       </div>
//       <div className='Page2' style={{backgroundColor: 'rgb(50, 50, 50)'}}></div>
//       <div className='Page3' style={{backgroundColor: 'rgb(150, 50, 50)'}}></div>
//     </div>
//   )
// }

// export function SpeechSynthesis() {
//   return (
//     <div>
//       <div className='Page1'>
//         <MainPageTemplate/>
//       </div>
//       <div className='Page2' style={{backgroundColor: 'rgb(50, 50, 50)'}}></div>
//       <div className='Page3' style={{backgroundColor: 'rgb(150, 50, 50)'}}></div>
//     </div>
//   )
// }

// export function LanguageTranslation() {
//   return (
//     <div>
//       <div className='Page1'>
//         <MainPageTemplate/>
//       </div>
//       <div className='Page2' style={{backgroundColor: 'rgb(50, 50, 50)'}}></div>
//       <div className='Page3' style={{backgroundColor: 'rgb(150, 50, 50)'}}></div>
//     </div>
//   )
// }




export function MainPageTemplate(){
    const {TechnologyDropdownEnable, setTechnologyDropdownEnable } = useContext(Context1);
    const navigate = useNavigate();

    const [Phone, setPhone] = useState(false);




    // useEffect(() => {
    //     const handleResize = () => {
    //     const zoomLevel = window.devicePixelRatio;
    //     const Screenwidth = window.innerWidth * zoomLevel/1920; // 30% of screen width
    //     // const Screenheight = window.innerHeight * 1; // 30% of screen height
    //     const width = 1920 / zoomLevel; // 30% of screen width
    //     const height = 1080 / zoomLevel; // 30% of screen height

    //     console.log(Screenwidth, width)
    //     // console.log(width, height, devicePixelRatio); // Log the new width
    //     document.documentElement.style.setProperty('--dynamic-width', `${width}px`);
    //     document.documentElement.style.setProperty('--dynamic-height', `${height}px`);
    //     document.documentElement.style.setProperty('--dynamic-Screenwidth', `${Screenwidth}`);
    //     };
    //     // Attach the event listener
    //     window.addEventListener('resize', handleResize);
    //     // Call handleResize to set the initial size
    //     handleResize();
    //     // Cleanup
    //     return () => {
    //     window.removeEventListener('resize', handleResize);
    //     };
    // }, []); // Empty dependency array means this effect runs once when the component mount





    useEffect(() => {
        const handleResize = () => {
            const zoomLevel = window.devicePixelRatio;
            const ScreenResolutionAvailableW = window.visualViewport.width;
            // const ScreenResolutionAvailableH = window.innerHeight
            const ScreenResolutionAvailableH = window.visualViewport.height;
            let ScreenResolutionW;
            let ScreenResolutionH;

            if (window.screen.orientation['type']=='landscape-primary'){
                
                // ScreenResolutionW= window.screen.width
                ScreenResolutionH= window.screen.height

                // if the available screen is less than horizontal height (the window is squished in width)else wthe width will follow the height (make the window square)
                if (ScreenResolutionAvailableW < ScreenResolutionH){
                    setPhone(true);
                    // ScreenResolutionW = ScreenResolutionAvailableW;
                }else{
                    setPhone(false);
                    // ScreenResolutionW = ScreenResolutionH;
                }
                
                ScreenResolutionW=ScreenResolutionAvailableW
                document.documentElement.style.setProperty('--dynamic-width', `${ScreenResolutionW}px`);
                // document.documentElement.style.setProperty('--dynamic-widthFull', `${ScreenResolutionH}px`);
                document.documentElement.style.setProperty('--dynamic-height', `${ScreenResolutionAvailableH}px`);

                document.documentElement.style.setProperty('--dynamic-text-size-gauge', `10px`);
                document.documentElement.style.setProperty('--dynamic-text-size', `${ScreenResolutionH*zoomLevel}`);

                console.log("a",window.screen.orientation['type'], ScreenResolutionW,ScreenResolutionH ,Phone)
            }else{
                setPhone(true);
                // ScreenResolutionH= window.screen.height
                // ScreenResolutionW= window.screen.width
                ScreenResolutionH= ScreenResolutionAvailableW
                ScreenResolutionW= ScreenResolutionAvailableW
                document.documentElement.style.setProperty('--dynamic-width', `${ScreenResolutionW}px`);
                document.documentElement.style.setProperty('--dynamic-height', `${ScreenResolutionH}px`);
                
                document.documentElement.style.setProperty('--dynamic-text-size-gauge', `5px`);
                document.documentElement.style.setProperty('--dynamic-text-size', `${0.4*ScreenResolutionH*zoomLevel}px`);

                console.log("b",window.screen.orientation['type'], ScreenResolutionW,ScreenResolutionH ,Phone)
            }
            // console.log(zoomLevel,ScreenResolutionW,ScreenResolutionH)
            
            document.documentElement.style.setProperty('--dynamic-Zoomlevel', `${zoomLevel}`);
        };
        // Attach the event listener
        window.addEventListener('resize', handleResize);
        // Call handleResize to set the initial size
        handleResize();
        // Cleanup
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);




    const handleClickCHATBOT = () => {window.open('https://dev.entrosystem.com', '_blank');};
    const handleClickTECHNOLOGY = () => {navigate('/technology');};
    const handleClickABOUT = () => {navigate('/about');};
    const handleClickENTROSYSTEM = () => {navigate('/');};
    
    const handleVectorEmbedding = () => {navigate('/');};
    const handleLargeLanguageModel = () => {navigate('/');};
    const handleSpeechTranscription = () => {navigate('/');};
    const handleSpeechSynthesis = () => {navigate('/');};
    const handleLanguageTranslation = () => {navigate('/');};

    if (Phone==true){
        return (
            <div>
                <div className="center-container0">
                    {/* <div className='CHATBOT' onClick={handleClickCHATBOT} style={{cursor: 'pointer'}} >CHATBOT</div>
                    <div className='ENTROSYSTEM'  onClick={handleClickENTROSYSTEM} style={{cursor: 'pointer', position:'relative',  left:'0px', top: '0px'}}>
                        <img src={'images/logo2.png'} style={{width: '100%', height: '100%',justifyContent: 'center',alignItems: 'center', position:'relative'}}/>
                        ENTROSYSTEM
                    </div> */}
                    <div className='ENTROSYSTEM'  onClick={handleClickENTROSYSTEM} style={{cursor: 'pointer', position:'relative',  left:'0px', top: '0px'}}><img src={'/images/logo2.png'} style={{width: '100%', height: '100%',justifyContent: 'center',alignItems: 'center', position:'relative'}}/></div>
                </div>
                <div className="center-container1" style={{top:'40px'}}>
                    <div className='CHATBOT' onClick={handleClickCHATBOT} style={{cursor: 'pointer'}} >CHATBOT</div>
                    <div className='TECHNOLOGY' onClick={handleClickTECHNOLOGY} style={{cursor: 'pointer'}} >TECHNOLOGY</div>
                    <div className='ABOUT' onClick={handleClickABOUT} style={{cursor: 'pointer'}} >ABOUT</div>
                </div>
            </div>
            )

    }else{
    return (
        <div>
            {/* width: '100%', height: '100%' */}
            {/* <img className='ENTROSYSTEM'  src={'images/logo2.png'} onClick={handleClickENTROSYSTEM} style={{cursor: 'pointer'}}/> */}
            
            {/* <div className='ENTROSYSTEM'  onClick={handleClickENTROSYSTEM} style={{cursor: 'pointer'}}><img src={'https://entrosystem.com/images/logo2.png'}style={{width: '100%', height: '100%'}}/></div> */}
            <div className='ENTROSYSTEM'  onClick={handleClickENTROSYSTEM} style={{cursor: 'pointer'}}><img src={'/images/logo2.png'}style={{width: '100%', height: '100%'}}/></div>
            {/* <div className='ENTROSYSTEM'  onClick={handleClickENTROSYSTEM} style={{cursor: 'pointer'}}>ENTROSYSTEM</div> */}
            <div className="center-container1">
                {/* <div className='PRODUCT' onClick={handleClickENTROSYSTEM}>PRODUCT</div> */}
                <div className='CHATBOT' onClick={handleClickCHATBOT} style={{cursor: 'pointer'}} >CHATBOT</div>
                <div className='TECHNOLOGY' onClick={handleClickTECHNOLOGY} style={{cursor: 'pointer'}} >TECHNOLOGY</div>

                {/* {!ChatOptionDropdownEnable && <div className='ChatOption'  onClick={() => setChatOptionDropdownEnable(!ChatOptionDropdownEnable) } > </div>}
                    {ChatOptionDropdownEnable && <ChatOptionDropdown/>} */}
                {/* <div className='TECHNOLOGY' onClick={() => setTechnologyDropdownEnable(!TechnologyDropdownEnable) }>
                    <div className='Submenu' onClick={handleVectorEmbedding}>     Vector Embedding</div>
                    <div className='Submenu' onClick={handleLargeLanguageModel}>  Large LanguageModel</div>
                    <div className='Submenu' onClick={handleSpeechSynthesis}>     Speech Synthesis</div>
                    <div className='Submenu' onClick={handleSpeechTranscription}> Speech Transcription</div>
                    <div className='Submenu' onClick={handleLanguageTranslation}> Language Translation</div>
                </div> */}
                <div className='ABOUT' onClick={handleClickABOUT} style={{cursor: 'pointer'}} >ABOUT</div>
            </div>
        </div>
        )
    }
}
